import { Col, Row, Typography, Divider } from "antd";
import { useEffect, useState } from "react";
import TopFiveResults from "./TopFiveResults";
import LatestResult from "./LatestResult";
import ExpectedAtTheStart from "./ExpectedAtTheStart";
import ExpectedAtTheFinish from "./ExpectedAtTheFinish";
import { DateTime } from "luxon";

const { Title, Text } = Typography;

const Clock = () => {
  const [now, setNow] = useState(DateTime.now());
  useEffect(() => {
    let interval = setInterval(() => {
      setNow(DateTime.now());
    }, 1000);
    return () => clearInterval(interval);
  }, [now]);

  return (
    <Row>
      <Text style={{ fontSize: 40, textAlign: "center", width: "100%" }}>{now.toFormat("HH:mm:ss")}</Text>
    </Row>
  );
};

const FinishResults = ({ distance, womanMode, ledScreen }) => {
  return (
    <>
      {!ledScreen && <Title>Finish</Title>}
      {!ledScreen && <Divider orientation="left">Laatste resultaat</Divider>}
      <LatestResult distance={distance} womanMode={womanMode} ledScreen={ledScreen} />
      <Divider orientation="left">Verwacht aan de finish</Divider>
      <ExpectedAtTheFinish ledScreen={ledScreen} />
      <Divider orientation="left">{`Top ${ledScreen ? "3" : "5"}${womanMode ? " (Dames)" : ""}`}</Divider>
      <TopFiveResults distance={distance} womanMode={womanMode} ledScreen={ledScreen} />
    </>
  );
};

const LiveResults = ({ distance, womanMode }) => {
  return (
    <Row justify="space-around">
      <Col xs={24} sm={7}>
        <Title>Start</Title>
        <Divider orientation="left">Klok</Divider>
        <Clock />
        <Divider orientation="left">Verwacht aan de start</Divider>
        <ExpectedAtTheStart />
      </Col>
      <Col xs={24} sm={16}>
        <FinishResults distance={distance} womanMode={womanMode} ledScreen={false} />
      </Col>
    </Row>
  );
};

export default LiveResults;
