import { Layout } from "antd";

import _ from "lodash";
import io from "socket.io-client";
import feathers from "@feathersjs/client";
import { Provider, useFind } from "figbird";
import FinishLed from "./FinishLed";
import LatestResultLed from "./LatestResultLed";

const socket = io(process.env.REACT_APP_SERVER_URL);
const client = feathers();

client.configure(feathers.socketio(socket, { timeout: 15000 }));
client.configure(
  feathers.authentication({
    storage: window.localStorage
  })
);

const { Content, Header, Footer } = Layout;

const LedLayout = () => {
  const { data: settings, status } = useFind("settings", {
    allPages: true,
    realtime: "refetch"
  });

  if (status === "loading") {
    return (
      <Layout className="layout led">
        <Content className="content"></Content>
      </Layout>
    );
  } else if (status === "error") {
    return (
      <Layout className="layout led">
        <Header className="header">ERROR: reload</Header>
        <Content className="content"></Content>
      </Layout>
    );
  }

  const distanceSetting = _.find(settings, (setting) => setting.key === "distance");
  const womanModeSetting = _.find(settings, (setting) => setting.key === "womanMode");

  if (!distanceSetting || !womanModeSetting) {
    return <p>Missing settings</p>;
  }

  const distance = Number(distanceSetting.value);
  const womanMode = womanModeSetting.value === "true";

  return (
    <Layout className="layout led">
      <Header className="header">
        <FinishLed distance={distance} womanMode={womanMode} />
      </Header>
      <Content className="content"></Content>
      <Footer className="footer">
        <LatestResultLed distance={distance} womanMode={womanMode} />
      </Footer>
    </Layout>
  );

  // return (
  //   <Layout className="layout led borders">
  //     <Content className="content"></Content>
  //     <Footer className="footer">
  //       <LatestResultLed distance={distance} womanMode={womanMode} />
  //       <FinishLed distance={distance} womanMode={womanMode} />
  //     </Footer>
  //   </Layout>
  // );
};

const Led = () => {
  return (
    <Provider feathers={client}>
      <LedLayout />
    </Provider>
  );
};

export default Led;
