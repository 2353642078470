import { Layout } from "antd";

import _ from "lodash";
import io from "socket.io-client";
import feathers from "@feathersjs/client";
import { Provider, useFind } from "figbird";
import TopFiveResults from "./TopFiveResults";

const socket = io(process.env.REACT_APP_SERVER_URL);
const client = feathers();

client.configure(feathers.socketio(socket, { timeout: 15000 }));
client.configure(
  feathers.authentication({
    storage: window.localStorage
  })
);

const { Content } = Layout;

const PodiumLayout = () => {
  const { data: settings, status } = useFind("settings", {
    allPages: true,
    realtime: "refetch"
  });

  if (status === "loading") {
    return (
      <Layout className="layout podium">
        <Content className="content">LOADING</Content>
      </Layout>
    );
  } else if (status === "error") {
    return (
      <Layout className="layout podium">
        <Content className="content">ERROR: reload</Content>
      </Layout>
    );
  }

  const distanceSetting = _.find(settings, (setting) => setting.key === "distance");
  const womanModeSetting = _.find(settings, (setting) => setting.key === "womanMode");

  if (!distanceSetting || !womanModeSetting) {
    return <p>Missing settings</p>;
  }

  const distance = Number(distanceSetting.value);
  const womanMode = womanModeSetting.value === "true";

  return (
    <Layout className="layout podium">
      <Content className="content">
        <TopFiveResults distance={distance} womanMode={womanMode} ledScreen={true} />
      </Content>
    </Layout>
  );
};

const Podium = () => {
  return (
    <Provider feathers={client}>
      <PodiumLayout />
    </Provider>
  );
};

export default Podium;
