import { useFind } from "figbird";
import _ from "lodash";
import { DateTime } from "luxon";
import { calculateSpeedFor } from "./Utils";
import { Space, Table } from "antd";
import { EndTimeResultLed } from "./components/EndTimeResult";

const LatestResultLed = ({ distance, womanMode }) => {
  const basicQueryOptions = {
    dnf: false,
    time: { $gte: "00:00:00" },
    $sort: { time: 1 }
  };
  const {
    status,
    data: allResults,
    error
  } = useFind("riders", {
    query: womanMode ? { ...basicQueryOptions, sex: "female" } : basicQueryOptions,
    allPages: true,
    realtime: "refetch"
  });

  if (status === "loading") {
    return "Laden...";
  } else if (status === "error") {
    return error.message;
  }

  const latestRiderResult = _.chain(allResults)
    .sortBy((result) => DateTime.fromISO(result.endTimeRecordedAt).toMillis() * -1)
    .first()
    .value();

  if (!latestRiderResult) {
    return (
      <div className="led_banner finish">
        <span className="led_title">FINISH</span>
        <p className="led_table">Nog niemand aan de finish!</p>
      </div>
    );
  }

  const bestRider = _.first(allResults);
  const latestRiderIndex = _.findIndex(allResults, (result) => result.id === latestRiderResult.id);
  const currentTimeIsBestTime = latestRiderIndex === 0;

  const contextResults = _.chain(allResults)
    .map((result, index) => ({ ...result, pos: index }))
    .filter((result, index) => index === latestRiderIndex)
    .value();

  const diffRider = currentTimeIsBestTime ? _.nth(allResults, 1) : bestRider; //TODO: test

  const columns = [
    {
      title: "Naam",
      key: "fullName",
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <span>{`${record.firstName} ${record.lastName} `}</span>
          <span className="bib">(nr: {record.bib})</span>
        </Space>
      )
    },
    {
      title: "Pos",
      key: "pos",
      width: "9%",
      className: "pos",
      render: (text, record) => <span className="position">{record.pos + 1}</span>
    },
    {
      title: "Tijd",
      key: "time",
      width: "20%",
      render: (text, record) => <EndTimeResultLed rider={record} diffRider={diffRider} showDiff={record.id === latestRiderResult.id} />
    },
    {
      title: "Snelheid",
      key: "speed",
      width: "18%",
      className: "speed",
      render: (text, record) => <span>{calculateSpeedFor(record, distance)} km/h</span>
    }
  ];

  const rowClassNameFn = (record) => {
    return record.pos === latestRiderIndex ? `latest-result ${womanMode ? "woman" : "man"}` : "context-result";
  };

  return (
    <div className="led_banner finish">
      <span className="led_title">FINISH</span>
      <Table
        className="led_table"
        dataSource={contextResults}
        columns={columns}
        rowClassName={rowClassNameFn}
        showHeader={false}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
};

export default LatestResultLed;
