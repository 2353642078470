import { Table, Tabs } from "antd";
import { useFind } from "figbird";
import { getFormattedTimeFor, calculateSpeedFor } from "./Utils";
import _ from "lodash";

const { TabPane } = Tabs;
const { Column } = Table;

const ResultsTable = ({ riders, distance, showCategory = true, showClub = true }) => {
  return (
    <Table dataSource={riders} rowKey="id" pagination={false} size="small" scroll={{ x: true }}>
      <Column title="Pos" key="position" render={(text, record, index) => <span>{index + 1}</span>} />
      <Column title="Naam" key="fullName" render={(text, record) => <span>{`${record.firstName} ${record.lastName}`}</span>} />
      <Column title="Tijd" key="time" render={(text, record) => <strong>{getFormattedTimeFor(record)}</strong>} />
      <Column title="km/h" key="speed" render={(text, record) => <span>{calculateSpeedFor(record, distance)}</span>} />
      <Column title="Nr" key="bib" dataIndex="bib" render={(text) => <span>{text}</span>} />
      {showCategory && <Column title="Categorie" key="category" dataIndex="category" />}
      {showClub && <Column title="Club" key="club" dataIndex="club" />}
    </Table>
  );
};

const FullResults = ({ distance }) => {
  const currentYear = 2022;
  const {
    status,
    data: riders,
    error
  } = useFind("riders", {
    query: {
      time: { $gte: "00:00:00" },
      $sort: { time: 1 },
      dnf: false
    },
    allPages: true,
    realtime: "refetch"
  });

  if (status === "loading") {
    return "Laden...";
  } else if (status === "error") {
    return error.message;
  }

  const calculateCategory = (rider) => {
    const age = currentYear - rider.yearOfBirth;
    if (age < 18) {
      return "Parel";
    }

    if (rider.sex === "female") {
      return "V";
    }

    if (age < 40) {
      return "M18";
    }
    return "M40";
  };

  const ridersWithCategory = _.map(riders, (rider) => {
    return { ...rider, category: calculateCategory(rider) };
  });

  return (
    <Tabs defaultActiveKey="general">
      <TabPane tab="Parel Man" key="parelm">
        <ResultsTable
          riders={_.filter(ridersWithCategory, (rider) => rider.category === "Parel" && rider.sex === "male")}
          distance={distance}
          showCategory={false}
        />
      </TabPane>
      <TabPane tab="Parel Vrouw" key="parelv">
        <ResultsTable
          riders={_.filter(ridersWithCategory, (rider) => rider.category === "Parel" && rider.sex === "female")}
          distance={distance}
          showCategory={false}
        />
      </TabPane>
      <TabPane tab="Algemeen" key="general">
        <ResultsTable riders={_.filter(ridersWithCategory, (rider) => rider.category !== "Parel")} distance={distance} />
      </TabPane>
      <TabPane tab="M18+" key="m18">
        <ResultsTable riders={_.filter(ridersWithCategory, (rider) => rider.category === "M18")} distance={distance} showCategory={false} />
      </TabPane>
      <TabPane tab="M40+" key="m40">
        <ResultsTable riders={_.filter(ridersWithCategory, (rider) => rider.category === "M40")} distance={distance} showCategory={false} />
      </TabPane>
      <TabPane tab="Man Wezel" key="maleWezel">
        <ResultsTable
          riders={_.filter(
            ridersWithCategory,
            (rider) => rider.category !== "Parel" && rider.sex === "male" && rider.city === "Wuustwezel"
          )}
          distance={distance}
          showCategory={false}
        />
      </TabPane>
      <TabPane tab="Dames" key="ladies">
        <ResultsTable
          riders={_.filter(ridersWithCategory, (rider) => rider.category !== "Parel" && rider.category === "V")}
          distance={distance}
          showCategory={false}
        />
      </TabPane>
      <TabPane tab="Dames Wezel" key="ladiesWezel">
        <ResultsTable
          riders={_.filter(
            ridersWithCategory,
            (rider) => rider.category !== "Parel" && rider.sex === "female" && rider.city === "Wuustwezel"
          )}
          distance={distance}
          showCategory={false}
        />
      </TabPane>
    </Tabs>
  );
};

export default FullResults;
