import { useFind } from "figbird";
import _ from "lodash";
import { DateTime } from "luxon";
import { calculateSpeedFor } from "./Utils";
import { Table, Tag } from "antd";
import { EndTimeResult } from "./components/EndTimeResult";

const LatestResult = ({ distance, womanMode, ledScreen }) => {
  const basicQueryOptions = {
    dnf: false,
    time: { $gte: "00:00:00" },
    $sort: { time: 1 }
  };
  const {
    status,
    data: allResults,
    error
  } = useFind("riders", {
    query: womanMode ? { ...basicQueryOptions, sex: "female" } : basicQueryOptions,
    allPages: true,
    realtime: "refetch"
  });

  if (status === "loading") {
    return "Laden...";
  } else if (status === "error") {
    return error.message;
  }

  const latestRiderResult = _.chain(allResults)
    .sortBy((result) => DateTime.fromISO(result.endTimeRecordedAt).toMillis() * -1)
    .first()
    .value();

  if (!latestRiderResult) {
    return <p>Nog niemand aan de finish!</p>;
  }

  const bestRider = _.first(allResults);
  const latestRiderIndex = _.findIndex(allResults, (result) => result.id === latestRiderResult.id);
  const currentTimeIsBestTime = latestRiderIndex === 0;
  const currentTimeIsLastTime = latestRiderIndex === allResults.length - 1;
  const getMoreContext = currentTimeIsBestTime || currentTimeIsLastTime;

  const contextResults = _.chain(allResults)
    .map((result, index) => ({ ...result, pos: index }))
    .filter((result, index) => Math.abs(latestRiderIndex - index) <= (getMoreContext ? 2 : 1))
    .value();

  const diffRider = currentTimeIsBestTime ? _.nth(contextResults, 1) : bestRider;

  const columns = [
    {
      title: "Pos",
      key: "pos",
      width: "2%",
      render: (text, record) => <span>{record.pos + 1}</span>
    },
    {
      title: "Naam",
      key: "fullName",
      render: (text, record) => <span>{`${record.firstName} ${record.lastName} (nr: ${record.bib})`}</span>
    },
    {
      title: "Tijd",
      key: "time",
      width: ledScreen ? "20%" : "28%",
      render: (text, record) => <EndTimeResult rider={record} diffRider={diffRider} showDiff={record.id === latestRiderResult.id} />
    },
    {
      title: "Toptijd",
      key: "toptijd",
      width: ledScreen ? "13%" : "7%",
      responsive: ["sm"],
      render: (text, record) => {
        if (currentTimeIsBestTime && record.id === bestRider.id) {
          return <Tag color="gold">TOPTIJD</Tag>;
        }
        return <span />;
      }
    },
    {
      title: "Snelheid",
      key: "speed",
      width: "18%",
      render: (text, record) => <span>{calculateSpeedFor(record, distance)} km/h</span>
    }
  ];

  const rowClassNameFn = (record) => {
    return record.pos === latestRiderIndex ? `latest-result ${womanMode ? "woman" : "man"}` : "context-result";
  };

  return (
    <Table dataSource={contextResults} columns={columns} rowClassName={rowClassNameFn} showHeader={false} pagination={false} rowKey="id" />
  );
};

export default LatestResult;
