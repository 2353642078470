import logo from "./logo.svg";
import { Layout, Menu, Divider, Row, Col } from "antd";

import _ from "lodash";
import io from "socket.io-client";
import feathers from "@feathersjs/client";
import { Provider, useFind } from "figbird";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import LiveResults from "./LiveResults";
import FullResults from "./FullResults";

const socket = io(process.env.REACT_APP_SERVER_URL);
const client = feathers();

client.configure(feathers.socketio(socket, { timeout: 15000 }));
client.configure(
  feathers.authentication({
    storage: window.localStorage
  })
);

const { Header, Content, Footer } = Layout;

const TopMenu = ({ location, showResultsTab }) => {
  return (
    <Row>
      <Col xs={5} sm={3} md={3} lg={3} xl={2} style={{ alignSelf: "center" }}>
        <div className="logo">
          <img src={logo} alt="Proloogse Baan" />
        </div>
      </Col>
      <Col xs={19} sm={20} md={20} lg={20} xl={21}>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[location.pathname]}>
          <Menu.Item key="/">
            <Link to="/">Live tracking</Link>
          </Menu.Item>
          {showResultsTab && (
            <Menu.Item key="/results">
              <Link to="/results">Resultaten</Link>
            </Menu.Item>
          )}
        </Menu>
      </Col>
    </Row>
  );
};

const ProloogseBaanLayout = () => {
  const {
    data: settings,
    status,
    error
  } = useFind("settings", {
    allPages: true,
    realtime: "refetch"
  });

  if (status === "loading") {
    return "Laden...";
  } else if (status === "error") {
    return error.message;
  }

  const distanceSetting = _.find(settings, (setting) => setting.key === "distance");
  const womanModeSetting = _.find(settings, (setting) => setting.key === "womanMode");
  const showResultsTabSetting = _.find(settings, (setting) => setting.key === "showResultsTab");

  if (!distanceSetting || !womanModeSetting || !showResultsTabSetting) {
    return <p>Missing settings</p>;
  }

  const distance = Number(distanceSetting.value);
  const womanMode = womanModeSetting.value === "true";
  const showResultsTab = showResultsTabSetting.value === "true";

  const TopMenuWithRouter = withRouter(TopMenu);
  return (
    <Layout className="layout">
      <Header className="pb-header">
        <TopMenuWithRouter showResultsTab={showResultsTab} />
      </Header>
      <Content className={`pb-content`}>
        <div className="site-layout-content">
          <Switch>
            {showResultsTab && (
              <Route path="/results">
                <FullResults distance={distance} />
              </Route>
            )}
            <Route path="/">
              <LiveResults distance={distance} womanMode={womanMode} />
            </Route>
          </Switch>
        </div>
      </Content>
      <Footer className="pb-footer">
        <Divider />
        <span>Proloogse Baan - 22 augustus 2024</span>
      </Footer>
    </Layout>
  );
};

const Standard = () => {
  return (
    <Router>
      <Provider feathers={client}>
        <ProloogseBaanLayout />
      </Provider>
    </Router>
  );
};

export default Standard;
