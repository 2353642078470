import { Duration } from "luxon";

const calculateSpeed = (millis, distance) => {
  const speed = Math.round((distance * 3600 * 1000 * 10) / millis) / 10;
  if (isNaN(speed)) {
    return "";
  }

  return speed;
};

const calculateSpeedFor = (rider, distance) => {
  if (!rider || !rider.time || !distance) {
    return "";
  }

  return calculateSpeed(Duration.fromISOTime(rider.time).toMillis(), distance);
};

const zeroPad = (nr) => {
  return Math.abs(nr) < 10 ? `0${nr}` : nr.toString();
};

const getAbsoluteStartTime = (rider, now) => {
  const relativeStartTime = Duration.fromISOTime(rider.relativeStartTime);
  const eventStartTime = now.startOf("day");
  return eventStartTime.plus(relativeStartTime);
};

const formatTime = (time) => {
  if (!time) {
    return time;
  }

  return time.toFormat("mm:ss.SSS").slice(0, 8);
};

const getFormattedTimeFor = (rider) => {
  if (!rider || !rider.time) {
    return "";
  }

  return formatTime(Duration.fromISOTime(rider.time));
};

export { zeroPad, getAbsoluteStartTime, formatTime, getFormattedTimeFor, calculateSpeedFor };
