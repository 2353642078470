import { useEffect, useState } from "react";
import { useFind } from "figbird";
import { Table, Typography } from "antd";
import { DateTime } from "luxon";
import { getAbsoluteStartTime } from "./Utils";
import _ from "lodash";

const { Text } = Typography;

const ExpectedAtTheFinish = ({ ledScreen }) => {
  const [now, setNow] = useState(DateTime.now());

  useEffect(() => {
    let interval = setInterval(() => {
      setNow(DateTime.now());
    }, 1000);
    return () => clearInterval(interval);
  }, [now]);

  const { status, data, error } = useFind("riders", {
    query: {
      dnf: false,
      $sort: { relativeStartTime: 1 },
      time: null,
      $limit: ledScreen ? 2 : 3
    },
    realtime: "refetch"
  });

  if (status === "loading") {
    return "Laden...";
  } else if (status === "error") {
    return error.message;
  }

  const columns = [
    {
      title: "Naam",
      key: "fullName",
      render: (text, record) => <span>{`${record.firstName} ${record.lastName} (nr: ${record.bib})`}</span>
    },
    {
      title: "Tijd",
      key: "time",
      width: ledScreen ? "51%" : "53%",
      render: (text, record, index) => {
        if (index !== 0) {
          return null;
        }
        return <ExpectedTimeAtTheFinish rider={record} now={now} />;
      }
    }
  ];

  const rowClassNameFn = (record, index) => {
    if (index === 0) {
      return "next-rider";
    }
    return "context-result";
  };

  return (
    <Table
      dataSource={_.filter(data, (rider) => now.toMillis() >= getAbsoluteStartTime(rider, now).toMillis())}
      columns={columns}
      showHeader={false}
      rowClassName={rowClassNameFn}
      pagination={false}
      rowKey="id"
    />
  );
};

const ExpectedTimeAtTheFinish = ({ rider, now }) => {
  const diff = now.diff(getAbsoluteStartTime(rider, now));
  if (!diff.isLuxonDuration) {
    return null;
  }

  return <Text>{diff.toFormat("mm:ss")}</Text>;
};

export default ExpectedAtTheFinish;
