import { useFind } from "figbird";
import _ from "lodash";
import { calculateSpeedFor } from "./Utils";
import { Space, Table } from "antd";
import { TrophyOutlined } from "@ant-design/icons";
import { EndTimeResult } from "./components/EndTimeResult";

const Position = ({ index, showTrophies = false }) => {
  const pos = index + 1;

  const mapping = {
    1: "#ffd700",
    2: "#c0c0c0",
    3: "#cd7f32"
  };

  if (pos <= 3) {
    return (
      <Space>
        {showTrophies && <TrophyOutlined style={{ color: mapping[pos] }} />}
        <span>{pos}</span>
      </Space>
    );
  }

  return <span>{pos}</span>;
};

const TopFiveResults = ({ distance, womanMode, ledScreen }) => {
  const basicQueryOptions = {
    dnf: false,
    $limit: 5,
    $sort: { time: 1 },
    time: { $gte: "00:00:00" }
  };
  const { status, data, error } = useFind("riders", {
    query: womanMode ? { ...basicQueryOptions, sex: "female" } : basicQueryOptions,
    realtime: "refetch"
  });

  if (status === "loading") {
    return "Laden...";
  } else if (status === "error") {
    return error.message;
  }

  const bestRider = _.first(data);

  const columns = [
    {
      title: "Pos",
      key: "position",
      render: (text, record, index) => <Position index={index} showTrophies={true} />
    },
    {
      title: "Naam",
      key: "fullName",
      render: (text, record) =>
        ledScreen ? (
          <span>{`${record.firstName} ${record.lastName}`}</span>
        ) : (
          <span>{`${record.firstName} ${record.lastName} (nr: ${record.bib})`}</span>
        )
    },
    {
      title: "Tijd",
      key: "time",
      width: ledScreen ? "20%" : "35%",
      render: (text, record) => <EndTimeResult rider={record} diffRider={bestRider} showDiff={bestRider.id !== record.id && !ledScreen} />
    },
    {
      title: "Snelheid",
      key: "speed",
      width: ledScreen ? "20%" : "18%",
      render: (text, record) => {
        return <span className="speed">{calculateSpeedFor(record, distance)} km/h</span>;
      }
    }
  ];

  return (
    <Table
      dataSource={data}
      className="topFiveResults"
      columns={columns}
      rowKey="id"
      pagination={false}
      showHeader={false}
      size={ledScreen ? "small" : "default"}
    />
  );
};

export default TopFiveResults;
