import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useFind } from "figbird";
import { Table } from "antd";
import _ from "lodash";
import { zeroPad, getAbsoluteStartTime } from "./Utils";

const ExpectedAtTheStart = () => {
  const [now, setNow] = useState(DateTime.now());
  useEffect(() => {
    let interval = setInterval(() => {
      setNow(DateTime.now());
    }, 1000);
    return () => clearInterval(interval);
  }, [now]);

  const { status, data, error } = useFind("riders", {
    query: {
      dnf: false,
      $sort: { relativeStartTime: 1 },
      $limit: 40,
      time: null
    },
    realtime: "refetch"
  });

  if (status === "loading") {
    return "Laden...";
  } else if (status === "error") {
    return error.message;
  }

  const columns = [
    {
      title: "Naam",
      key: "fullName",
      render: (text, record) => <span>{`${record.firstName} ${record.lastName} (nr: ${record.bib})`}</span>
    },
    {
      title: "Starttijd",
      key: "startTime",
      render: (text, record) => {
        return <span>{getAbsoluteStartTime(record, now).toFormat("HH:mm")}</span>;
      }
    },
    {
      title: "Tijd",
      key: "diff",
      render: (text, record) => {
        const diff = getAbsoluteStartTime(record, now).diff(now);
        const secondsUntilStart = Math.round(diff.toMillis() / 1000);

        if (secondsUntilStart > 30 || secondsUntilStart < 0) {
          return <span>&nbsp;&nbsp;&nbsp;</span>;
        }
        return <span>+{zeroPad(secondsUntilStart)}</span>;
      }
    }
  ];

  const rowClassNameFn = (record, index) => {
    if (index === 0) {
      return "next-rider-at-start";
    }
    return "context-result";
  };

  return (
    <Table
      dataSource={_.chain(data)
        .filter((rider) => now.toMillis() < getAbsoluteStartTime(rider, now).toMillis())
        .take(10)
        .value()}
      columns={columns}
      showHeader={false}
      rowClassName={rowClassNameFn}
      pagination={false}
      rowKey="id"
    />
  );
};

export default ExpectedAtTheStart;
