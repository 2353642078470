import Standard from "./Standard";
import Led from "./Led";
import Podium from "./Podium";
import "./App.less";

const App = () => {
  const ledScreen = window.location.pathname === "/led" || window.location.pathname === "/led/";
  const podium = window.location.pathname === "/podium" || window.location.pathname === "/podium/";
  if (ledScreen) {
    return <Led />;
  }
  if (podium) {
    return <Podium />;
  }
  return <Standard />;
};

export default App;
