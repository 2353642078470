import { formatTime, getFormattedTimeFor } from "../Utils";
import { Duration } from "luxon";
import { Space, Typography } from "antd";

const { Text } = Typography;

const EndTimeResult = ({ rider, diffRider, showDiff = false }) => {
  if (!rider || !rider.time) {
    return <span />;
  }
  if (!diffRider || !diffRider.time) {
    return <span>{getFormattedTimeFor(rider)}</span>;
  }

  const millisDiff = Duration.fromISOTime(rider.time).toMillis() - Duration.fromISOTime(diffRider.time);
  const advantage = millisDiff < 0;
  const diff = Duration.fromMillis(Math.abs(millisDiff));

  return (
    <Space align="center">
      <span className="endTimeResult">{getFormattedTimeFor(rider)}</span>
      {showDiff && (
        <Text className="time-diff" type={advantage ? "success" : "danger"}>
          {advantage ? "-" : "+"} {formatTime(diff)}
        </Text>
      )}
    </Space>
  );
};

const EndTimeResultLed = ({ rider, diffRider, showDiff = false }) => {
  if (!rider || !rider.time) {
    return <span />;
  }
  if (!diffRider || !diffRider.time) {
    return <span>{getFormattedTimeFor(rider)}</span>;
  }

  const millisDiff = Duration.fromISOTime(rider.time).toMillis() - Duration.fromISOTime(diffRider.time);
  const advantage = millisDiff < 0;
  const diff = Duration.fromMillis(Math.abs(millisDiff));

  return (
    <Space align="center" direction="vertical" size={0}>
      <span>{getFormattedTimeFor(rider)}</span>
      {showDiff && (
        <Text className="time-diff" type={advantage ? "warning" : "danger"}>
          {advantage ? "-" : "+"} {formatTime(diff)}
        </Text>
      )}
    </Space>
  );
};

export { EndTimeResult, EndTimeResultLed };
